export const adjective = {
	singular: "provider",
	plural: "providers",
};

export const verb = {
	singular: "caring",
};

export const job = {
	singular: "care",
	plural: "care",
};
